import React, { Component } from 'react'

import {Helmet} from "react-helmet";

import Accordion from './blocks/Accordion'
import Contact from './blocks/Contact'
import ContentOnImage from './blocks/ContentOnImage'
import Countdown from './blocks/Countdown'
import ImageText from './blocks/ImageText'
import Map from './blocks/Map'
import SquareImageBuckets from './blocks/SquareImageBuckets'
import TicketDates from './blocks/TicketDates'
import Tickets from './blocks/Tickets'
import Content from './blocks/Content'

class Page extends Component {
	components = {
		'accordion': Accordion,
		'contact': Contact,
		'content-on-image': ContentOnImage,
		'countdown': Countdown,
		'image-text': ImageText,
		'map': Map,
		'square-image-buckets': SquareImageBuckets,
		'ticket-dates': TicketDates,
		'tickets': Tickets,
		'content': Content,
	};

	render() {
		return (
			<div className={ "page-main "  + this.props.slug }>
				<Helmet>
		            <title>Rock the River - { this.props.title }</title>
		            <meta name="description" content={this.props.metaDescription} />
		        </Helmet>
				<div className="blocks">
					{this.props.blocks.map((block, i) => {
						let BlockName = this.components[block.type];
						return <BlockName key={i} content={JSON.parse(block.content)} />
					})}
				</div>
			</div>
		)
	}
}

export default Page