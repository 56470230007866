import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import Textarea from 'core/components/form/Textarea'
import ImageUpload from 'core/components/form/ImageUpload'
import MultiRowWrapper from 'core/components/form/MultiRowWrapper'

export const fieldDefaults = {
	'title': '',
	'image': {},
	'details': [],
}

class DetailsForm extends Component {
	render() {
		return (
			<div className="repeater">
				
				{this.props.rows.map((hoursItem, i) => {
					return (
						<div className="repeater-section" key={i}>
							<Input type="text" name="title" label="Title" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={hoursItem.title} />
							<Textarea name="content" label="Content" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={hoursItem.content} />
							
							<button type="button" className="remove" onClick={() => this.props.removeRow(i)}></button>
						</div>
					)
				})}
				<button type="button" className="add" onClick={() => this.props.addRow()}>
					Add Details <span>+</span>
				</button>
			</div>
		)
	}
} 

const DetailsField = MultiRowWrapper(DetailsForm, {
        title: '',
        content: ''
    });

export class Contact extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">Contact</p>
				<div className="block-fields">
					<Input type="text" name="title" label="Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.title} />
					<ImageUpload name="image" onInputChange={(name, value) => this.props.onChange(name, value)} value={this.props.content.image._id} />
					<DetailsField name="details" onChange={this.props.onChange} rows={this.props.content.details} />
				</div>
			</div>
		);
	}
}