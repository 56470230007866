import React, { Component } from 'react';
import Field from './Field'

class Checkbox extends Component {
	constructor(props) {
		super(props);

		this.toggleSelected = this.toggleSelected.bind(this);
	}

	toggleSelected(itemID) {
		if(this.props.value)
			this.props.onInputChange(this.props.name, false)
		else
			this.props.onInputChange(this.props.name, true)
	}

	render() {
		// const { label, errors, removeFocus, ...rest} = this.props

		return (
			<Field {...this.props}>
				<div className={"checkmark" + (this.props.value ? ' checked' : '')} onClick={() => this.toggleSelected()}></div>
			</Field>
		)
	}
}

export default Checkbox;