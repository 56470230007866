import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchUsers, setUsersOrder, setUsersPage, deleteUser, setUsersSearch } from '../actions'
import { getUsers, getUsersPage, getUsersPerPage, getUsersOrderBy, getUsersOrderDir, getUsersTotalPages, getUsersFetching, getUsersStatus, getUserDeleteSuccess, getUsersSearch } from '../selectors'
import Listing from 'admin/components/Listing'
import Delete from 'admin/components/Delete'
import Input from 'core/components/form/Input'
import moment from 'moment'

class UserListingContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			search: ''
		}

		this.onSearchChange = this.onSearchChange.bind(this)
	}

	onSearchChange(name, value) {
		this.setState({
			search: value
		})
	}

	render() {
		let columns = [
			{
				id: 'firstname',
				display: 'Name',
				value: item => {
					return <Link to={"/admin/dashboard/user/editor/" + item.id }>{item.firstname} {item.lastname}</Link>
				}
			},
			{
				id: 'email',
				display: 'Email'
			},
			{
				id: 'created_at',
				display: 'Date',
				value: item => {
					return moment.utc(item.created_at.date).local().format('MMMM D, YYYY - hh:mm a')
				}
			},
			{
				id: 'actions',
				display: '',
				value: item => {
					return <Delete onDelete={() => this.props.delete(item.id)} />
				}
			}
		];
		return (
			<div className="listing-wrapper">
				<div className="tab-nav">
					<span className="tab is-active">All Users</span>
				</div>
				<div className="listing-actions">
					<Link className="button" to="/admin/dashboard/user/editor">Add New User</Link>
					<div className="search-list">
						<Input type="search" name="search" label="Search" onInputChange={this.onSearchChange} />
						<button className="button" onClick={() => this.props.setSearch(this.state.search)}>Search</button>
					</div>
				</div>
				<div className="main-content">
					<Listing 
						columns={columns}
						{...this.props}
					/>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state = {}) => {
    return {
    	items: getUsers(state),
    	page: getUsersPage(state),
    	perPage: getUsersPerPage(state),
    	orderBy: getUsersOrderBy(state),
    	orderDir: getUsersOrderDir(state),
    	totalPages: getUsersTotalPages(state),
    	isLoading: getUsersFetching(state),
    	status: getUsersStatus(state),
    	deleteSuccess: getUserDeleteSuccess(state),
    	search: getUsersSearch(state)
    }
};

const mapDispatchToProps = dispatch => {
	return {
		loadItems: data => {
			dispatch(fetchUsers(data))
		},

		setOrderBy: (orderBy, orderDir) => {
			dispatch(setUsersOrder(orderBy, orderDir))
		},

		setPage: page => {
			dispatch(setUsersPage(page))
		},

		setSearch: search => {
			dispatch(setUsersSearch(search))
		},

		delete: id => {
			dispatch(deleteUser(id))
		}
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(UserListingContainer)