import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import Loader from 'admin/components/Loader'

class Login extends Component {
	render() {
		const { values, errors } = this.props

		return (
			<div className="form-wrapper">
				<form className="login-form" onSubmit={this.props.onSubmit}>
					<div className="input-wrapper">
						<Input type="text" name="email" label="Email" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.email} errors={errors.email} />
					</div>
					<div className="input-wrapper">
						<Input type="password" name="password" label="Password" onInputChange={this.props.onInputChange} removeFocus={this.props.onRemoveFocus} value={values.password} errors={errors.password} />
					</div>
					<button className={"button" + (this.props.isLoading ? ' loading' : '')} disabled={this.props.isDisabled}>
						{
							this.props.isLoading ?
							<Loader/>
							:
							<span>Login</span>
						}
					</button>
				</form>
				<div className="form-error">
					{this.props.formError} 
					{
						this.props.formError === 'Email not verified' ? 
						<button className="form-action" type="button" onClick={() => this.props.onResendVerification(values.email)}>Resend verification</button>
						: ''
					}
				</div>
			</div>
		)
	}
}

export default Login;