import { createSelector } from 'reselect'

export const getAllSettings = (state) => state.settings.settings
export const getSettingsFetching = (state) => state.settings.isFetching

export const getSetting = createSelector(
	[getAllSettings, (state, settingName) => settingName],
	(settings, settingName) => {
		if(settings !== undefined && settings[settingName] !== undefined) {
			return settings[settingName];
		}

		return false;
	}
)