// import React, {Component} from 'react'
import { connect } from 'react-redux'
import Header from 'frontend/components/layout/Header'
import { getSetting } from 'admin/modules/Settings/selectors'

function mapStateToProps(state) {
	
  return {
    logo: JSON.parse(getSetting(state, 'logo'))
  }
}


export default connect(mapStateToProps)(Header)