import {
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGIN_VALIDATION,
	LOGIN_FAILURE,
	LOGOUT_REQUEST,
	LOGOUT_SUCCESS,
	PASSWORD_RESET_EMAIL_REQUEST,
	PASSWORD_RESET_EMAIL_SUCCESS,
	PASSWORD_RESET_EMAIL_VALIDATION,
	PASSWORD_RESET_EMAIL_FAILURE,
	PASSWORD_RESET_REQUEST,
	PASSWORD_RESET_SUCCESS,
	PASSWORD_RESET_VALIDATION,
	PASSWORD_RESET_FAILURE,
} from './actionTypes'

import { handleErrors } from 'core/modules/Error/actions'
import API from 'API'

function requestLogin(creds) {
	return {
		type: LOGIN_REQUEST,
		isFetching: true,
		isAuthenticated: false,
		creds
	}
}

export function receiveLogin(token, user) {
	return {
		type: LOGIN_SUCCESS,
		isFetching: false,
		isAuthenticated: true,
		token,
		user
	}
}

export function loginValidation(errors) {
	// console.log(errors);
	return {
		type: LOGIN_VALIDATION,
		isFetching: false,
		isAuthenticated: false,
		errors: errors
	}
}

export function loginError(error) {
	return {
		type: LOGIN_FAILURE,
		isFetching: false,
		isAuthenticated: false,
		error
	}
}

export function loginUser(creds) {
	return dispatch => {
		// Before ajax call
		dispatch(requestLogin(creds))
		let CLAPI = new API(dispatch);
		return CLAPI.login(creds.email, creds.password)
			.then(response => {
                localStorage.setItem('cltoken', response.data.data.access_token);
                localStorage.setItem('cluser', JSON.stringify(response.data.data.user));
                dispatch(receiveLogin(response.data.data.access_token, JSON.stringify(response.data.data.user)));
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(loginValidation(error.response.data.errors));
            	} else {
            		if(error.response && error.response.status === 401) {
            			dispatch(loginError(error.response.data.message));
            		} else {
	            		dispatch(loginError());
	            		dispatch(handleErrors(error));
	            	}
            		
            	}
            });
	}
}

export function resendVerification(email) {
	return dispatch => {
		let CLAPI = new API(dispatch);
		return CLAPI.resendVerification(email);
	}
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true
  }
}

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false
  }
}

export function logoutUser() {
  return dispatch => {
    dispatch(requestLogout())
    localStorage.removeItem('cltoken')
    localStorage.removeItem('cluser')
    dispatch(receiveLogout())
  }
}

function requestPasswordResetEmail() {
	return {
		type: PASSWORD_RESET_EMAIL_REQUEST,
		isFetching: true
	}
}

export function receivePasswordResetEmail() {
	return {
		type: PASSWORD_RESET_EMAIL_SUCCESS,
		isFetching: false
	}
}

export function passwordResetEmailValidation(errors) {
	// console.log(errors);
	return {
		type: PASSWORD_RESET_EMAIL_VALIDATION,
		isFetching: false,
		errors: errors
	}
}

export function passwordResetEmailError() {
	return {
		type: PASSWORD_RESET_EMAIL_FAILURE,
		isFetching: false
	}
}

export function sendPasswordResetEmail(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestPasswordResetEmail())
		let CLAPI = new API(dispatch);
		return CLAPI.sendPasswordResetEmail(data)
			.then(response => {
				// console.log(response);
                dispatch(receivePasswordResetEmail());
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(passwordResetEmailValidation(error.response.data.errors));
            	} else {
            		dispatch(passwordResetEmailError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}

function requestPasswordReset() {
	return {
		type: PASSWORD_RESET_REQUEST,
		isFetching: true
	}
}

export function receivePasswordReset() {
	return {
		type: PASSWORD_RESET_SUCCESS,
		isFetching: false
	}
}

export function passwordResetValidation(errors) {
	// console.log(errors);
	return {
		type: PASSWORD_RESET_VALIDATION,
		isFetching: false,
		errors: errors
	}
}

export function passwordResetError() {
	return {
		type: PASSWORD_RESET_FAILURE,
		isFetching: false
	}
}

export function resetPassword(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestPasswordReset())
		let CLAPI = new API(dispatch);
		return CLAPI.resetPassword(data)
			.then(response => {
				// console.log(response);
                dispatch(receivePasswordReset());
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(passwordResetValidation(error.response.data.errors));
            	} else {
            		dispatch(passwordResetError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}