import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getSetting } from 'admin/modules/Settings/selectors'
import { default as Footer } from 'frontend/containers/layout/FooterContainer';

class NotFoundPage extends Component {
	componentDidMount() {
	  document.title = 'WJ CMS - 404 Not Found';
	}

	render() {
		return (
			<div className="error-page">
				<div class="page-main">
					{this.props.errorImage !== null ? 
						<div className="block content-on-image">
							<div className="block-inner">
								<div className="block-background" style={{ backgroundImage: 'url(' + this.props.errorImage.thumbnailUrl + '?w=1640&h=600&fit=crop)'}}>
									<div className="block-main">
										{ this.props.errorTitle !== '' ? <h2 class="block-title">{ this.props.errorTitle }</h2> : ''}
										{ this.props.errorSubtitle !== '' ? <div class="block-content">{ this.props.errorSubtitle }</div> : ''}
										{ this.props.errorLinkURL !== '' ? <a class="button" href={this.props.errorLinkURL}>{ this.props.errorLinkText }</a> : ''}
									</div>
								</div>
							</div>
						</div> : '' }	

						
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
  return {
    errorImage: JSON.parse(getSetting(state, 'error_image')),
    errorTitle: getSetting(state, 'error_title'),
    errorSubtitle: getSetting(state, 'error_subtitle'),
    errorLinkText: getSetting(state, 'error_link_text'),
    errorLinkURL: getSetting(state, 'error_link_url'),
  }
}

export default connect(mapStateToProps)(NotFoundPage)