import {
	GET_USER_REQUEST,
	GET_USER_SUCCESS,
	GET_USER_FAILURE,
	SAVE_USER_REQUEST,
	SAVE_USER_SUCCESS,
	SAVE_USER_VALIDATION,
	SAVE_USER_FAILURE,
	DELETE_USER_REQUEST,
	DELETE_USER_SUCCESS,
	DELETE_USER_FAILURE,
	GET_USERS_REQUEST,
	GET_USERS_SUCCESS,
	GET_USERS_FAILURE,
	SET_USERS_ORDER,
	SET_USERS_PAGE,
	SET_USERS_SEARCH,
	GET_ROLES_REQUEST,
	GET_ROLES_SUCCESS,
	GET_ROLES_FAILURE,
} from './actionTypes'

import { handleErrors } from 'core/modules/Error/actions'
import API from 'API'

function requestGetUser() {
	return {
		type: GET_USER_REQUEST,
		isFetching: true
	}
}

export function receiveGetUser(user) {
	return {
		type: GET_USER_SUCCESS,
		isFetching: false,
		user
	}
}

export function getUserError() {
	return {
		type: GET_USER_FAILURE,
		isFetching: false
	}
}

export function getUser(id) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetUser())
		let CLAPI = new API(dispatch);
		return CLAPI.getUser(id)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetUser(response.data.data));
                // return response
            })
            .catch(error => {
            	dispatch(getUserError());
            	dispatch(handleErrors(error));
            });
	}
}

function requestSaveUser() {
	return {
		type: SAVE_USER_REQUEST,
		isFetching: true
	}
}

export function receiveSaveUser(user) {
	return {
		type: SAVE_USER_SUCCESS,
		isFetching: false,
		user
	}
}

export function saveUserValidation(errors) {
	// console.log(errors);
	return {
		type: SAVE_USER_VALIDATION,
		isFetching: false,
		errors: errors
	}
}

export function saveUserError() {
	return {
		type: SAVE_USER_FAILURE,
		isFetching: false
	}
}

export function saveUser(id, data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestSaveUser())
		let CLAPI = new API(dispatch);
		return CLAPI.saveUser(id, data)
			.then(response => {
				// console.log(response);

				let userData = response.data.data;
				let loggedInUser = JSON.parse(localStorage.getItem('cluser'));
				// console.log(userData.id, loggedInUser.id)
				if(userData.id === loggedInUser.id)
					localStorage.setItem('cluser', JSON.stringify(userData));
                dispatch(receiveSaveUser(response.data.data));
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(saveUserValidation(error.response.data.errors));
            	} else {
            		dispatch(saveUserError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}

export function createUser(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestSaveUser())
		let CLAPI = new API(dispatch);
		return CLAPI.createUser(data)
			.then(response => {
				// console.log(response);
                dispatch(receiveSaveUser(response.data.data));
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(saveUserValidation(error.response.data.errors));
            	} else {
            		dispatch(saveUserError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}

function requestDeleteUser() {
	return {
		type: DELETE_USER_REQUEST,
		isFetching: true
	}
}

export function receiveDeleteUser() {
	return {
		type: DELETE_USER_SUCCESS,
		isFetching: false
	}
}

export function saveDeleteUserError() {
	return {
		type: DELETE_USER_FAILURE,
		isFetching: false
	}
}

export function deleteUser(id, data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestDeleteUser())
		let CLAPI = new API(dispatch);
		return CLAPI.deleteUser(id, data)
			.then(response => {
                dispatch(receiveDeleteUser());
            })
            .catch(error => {
            	dispatch(saveDeleteUserError());
            	dispatch(handleErrors(error));
            });
	}
}

function requestGetUsers() {
	return {
		type: GET_USERS_REQUEST,
		isFetching: true
	}
}

export function receiveGetUsers(users, totalPages) {
	return {
		type: GET_USERS_SUCCESS,
		isFetching: false,
		users,
		totalPages
	}
}

export function getUsersError() {
	return {
		type: GET_USERS_FAILURE,
		isFetching: false
	}
}

export function fetchUsers(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetUsers())
		let CLAPI = new API(dispatch);
		return CLAPI.getUsers(data)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetUsers(response.data.data, response.data.meta.last_page));
                // return response
            })
            .catch(error => {
            	dispatch(getUsersError());
            	dispatch(handleErrors(error));
            });
	}
}

export function setUsersOrder(orderBy, orderDir) {
	return {
		type: SET_USERS_ORDER,
		orderBy,
		orderDir
	}
}

export function setUsersPage(page) {
	return {
		type: SET_USERS_PAGE,
		page
	}
}

export function setUsersSearch(search) {
	return {
		type: SET_USERS_SEARCH,
		search
	}
}

function requestGetRoles() {
	return {
		type: GET_ROLES_REQUEST,
		isFetching: true
	}
}

export function receiveGetRoles(roles) {
	return {
		type: GET_ROLES_SUCCESS,
		isFetching: false,
		roles
	}
}

export function getRolesError() {
	return {
		type: GET_ROLES_FAILURE,
		isFetching: false
	}
}

export function fetchActiveRoles(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetRoles())
		let CLAPI = new API(dispatch);
		return CLAPI.getActiveRoles(data)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetRoles(response.data.data));
                // return response
            })
            .catch(error => {
            	dispatch(getRolesError());
            	dispatch(handleErrors(error));
            });
	}
}