import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import ImageUpload from 'core/components/form/ImageUpload'
import Textarea from 'core/components/form/Textarea'
import MultiRowWrapper from 'core/components/form/MultiRowWrapper'

export const fieldDefaults = {
	'dates': [],
}

class TimesForm extends Component {
	render() {
		return (
			<div className="repeater">
				
				{this.props.rows.map((time, i) => {
					return (
						<div className="repeater-section" key={i}>
							<Input type="text" name="time" label="Time" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={time.time} />
							<Input type="text" name="title" label="Title" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={time.title} />
							<ImageUpload name="image" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={time.image._id} />
							
							<button type="button" className="remove" onClick={() => this.props.removeRow(i)}></button>
						</div>
					)
				})}
				<button type="button" className="add" onClick={() => this.props.addRow()}>
					Add Times <span>+</span>
				</button>
			</div>
		)
	}
} 

const TimesField = MultiRowWrapper(TimesForm, {
    time: '',
    title: '',
    image: {}
});

class DatesForm extends Component {
	render() {
		return (
			<div className="repeater">
				
				{this.props.rows.map((date, i) => {
					return (
						<div className="repeater-section" key={i}>
							<Input type="text" name="title" label="Title" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={date.title} />
							<div className="field-group">
								<Input type="text" name="link_text" label="Link Text" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={date.link_text} />
								<Input type="text" name="link_url" label="Link URL" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={date.link_url} />
							</div>
							<TimesField name="times" onChange={(name, value) => this.props.onRowChange(i, name, value)} rows={date.times} />
							<button type="button" className="remove" onClick={() => this.props.removeRow(i)}></button>
						</div>
					)
				})}
				<button type="button" className="add" onClick={() => this.props.addRow()}>
					Add Dates <span>+</span>
				</button>
			</div>
		)
	}
} 

const DatesField = MultiRowWrapper(DatesForm, {
    title: '',
    link_text: '',
    link_url: '',
    times: []
});

export class TicketDates extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">Ticket Dates</p>
				<div className="block-fields">
					<DatesField name="dates" onChange={this.props.onChange} rows={this.props.content.dates} />
				</div>
			</div>
		);
	}
}