// import React, {Component} from 'react'
import { connect } from 'react-redux'
import Footer from 'frontend/components/layout/Footer'
import { getSetting } from 'admin/modules/Settings/selectors'

function mapStateToProps(state) {
  return {
    copyright: getSetting(state, 'copyright'),
    sponsors: JSON.parse(getSetting(state, 'sponsors')),
    logo: JSON.parse(getSetting(state, 'footer_logo'))
  }
}


export default connect(mapStateToProps)(Footer)