import React, { Component } from 'react';
import Loader from 'admin/components/Loader'
import { Prompt, Link, Redirect } from 'react-router-dom'

class Create extends Component {
	constructor(props) {
		super(props);

		this.state = {
	    	toListing: false
	    }
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.deleteSuccess) {
			this.setState({
				toListing: true
			})
		}
	}

	shouldBlockNavigation() {
		return this.props.formUsed && !this.props.saveSuccess
	}

	componentDidUpdate() {
	  if (this.shouldBlockNavigation()) {
	    window.onbeforeunload = () => true
	  } else {
	    window.onbeforeunload = undefined
	  }
	}

	render() {
		return (
			<div>
				<Prompt
      				when={this.shouldBlockNavigation()}
      				message='Changes you made may not be saved.'
    			/>
			{	
				this.props.saveSuccess ?
				<Redirect to={ this.props.editorLink + this.props.editValues.id }/>
				:
				<div className="model-form edit-form">
					<div className="form-heading">
						<Link to={this.props.backLink} className="back-link">Exit Editor</Link>
						<h1 className="editor-title">Add New {this.props.name}</h1>
					</div>
					<div className="form-actions">
						<div className="form-buttons">
							{
								this.props.buttons ? 
									this.props.buttons.map((button) => {
										return <button className="button" onClick={button.onClick}>{ button.title }</button>
									})
									:
									''
							}
							{ this.props.hideStatuses ?
								
							 	<div className="submit-actions">
									<button className="button submit" onClick={(event) => this.props.onSubmit(event)} disabled={this.props.isDisabled}> 
										{
											this.props.isLoading ?
											<Loader/>
											:
											<span>Publish {this.props.name}</span>
										}
										
									</button>
								</div>
							: 

								<div className="submit-actions">
									<button className="button gray submit-draft" onClick={(event) => this.props.onSubmit(event, {'status': 'draft'})} disabled={this.props.isDisabled}>
										{
											this.props.isLoading ?
											<Loader/>
											:
											<span>Save As Draft</span>
										}
									</button>

									<button className="button submit" onClick={(event) => this.props.onSubmit(event, {'status': 'active'})} disabled={this.props.isDisabled}> 
										{
											this.props.isLoading ?
											<Loader/>
											:
											<span>Publish {this.props.name}</span>
										}
										
									</button>
								</div>
							}
						</div>
					</div>
					<div className="form-content">
						{(this.props.isLoading ? <Loader/> : '')}
						{React.cloneElement(this.props.children, {...this.props})}
					</div>
				</div>
			}
			</div>
		)
	}
}

export default Create;