import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import DatePicker from 'core/components/form/DatePicker'
import Textarea from 'core/components/form/Textarea'
import MultiRowWrapper from 'core/components/form/MultiRowWrapper'

export const fieldDefaults = {
	title: '',
    featured_tickets: [],
    tickets: []
}

class TicketsForm extends Component {
	render() {
		return (
			<div className="repeater">
				
				{this.props.rows.map((ticket, i) => {
					return (
						<div className="repeater-section" key={i}>
							<Input type="text" name="title" label="Title" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={ticket.title} />
							<Input type="text" name="date" label="Date" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={ticket.date} />
							<Input type="text" name="price" label="Price" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={ticket.price} />
							<div className="field-group">
								<Input type="text" name="link_text" label="Link Text" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={ticket.link_text} />
								<Input type="text" name="link_url" label="Link URL" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={ticket.link_url} />
							</div>
							<button type="button" className="remove" onClick={() => this.props.removeRow(i)}></button>
						</div>
					)
				})}
				<button type="button" className="add" onClick={() => this.props.addRow()}>
					{this.props.addText} <span>+</span>
				</button>
			</div>
		)
	}
} 

const TicketsField = MultiRowWrapper(TicketsForm, {
    title: '',
    date: '',
    price: '',
    link_text: '',
    link_url: ''
});

export class Tickets extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">Tickets</p>
				<div className="block-fields">
					<Input type="text" name="title" label="Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.title} />
					<TicketsField name="featured_tickets" onChange={this.props.onChange} rows={this.props.content.featured_tickets} addText="Add Featured Ticket" />
					<TicketsField name="tickets" onChange={this.props.onChange} rows={this.props.content.tickets} addText="Add Ticket"/>
				</div>
			</div>
		);
	}
}