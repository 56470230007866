import React, { Component } from 'react'
import moment from 'moment'

class Countdown extends Component {
	formatDates(start, end) {
		// $startTime = strtotime($start);
		// $dateString = date('F j', $startTime); 

		// if(!empty($end)) {
		// $endTime = strtotime($end);
		// $startMonth = date('F', $startTime);
		// $endMonth = date('F', $endTime);
		// $startYear = date('F', $startTime);
		// $endYear = date('F', $endTime);
		// if($startYear == $endYear) {
		// 	if($startMonth == $endMonth) {
		// 	$dateString .= date(' - j, Y', $endTime);
		// 	} else {
		// 	$dateString .= date(' - F j, Y', $endTime);
		// 	}
		// } else {
		// 	$dateString .= date(', Y', $startTime).date(' - F j, Y', $endTime);
		// }
		// } else {
		// $dateString .= date(', Y', $startTime);
		// }
		// return $dateString;
		let startMoment = moment(start);
		let endMoment = moment(end);
		
		let dateString = startMoment.format('MMMM D');
		if(endMoment !== 'Invalid Date') {
			if(startMoment.isSame(endMoment, 'year')) {
				if(startMoment.isSame(endMoment, 'month')) {
					dateString += endMoment.format(' - D, YYYY');
				} else {
					dateString += endMoment.format(' - MMMM D, YYYY');
				}
			} else {
				dateString += startMoment.format(', YYYY');
				dateString += endMoment.format(' - MMMM D, YYYY');
			}
		} else {
			dateString += startMoment.format(', YYYY');
		}
		return dateString;
	}

	render() {
		const { start, end, style } = this.props.content;
		let dateString = this.formatDates(start, end);
		let startMoment = moment(start)
		let nowMoment = moment();
		let duration = moment.duration(startMoment.diff(nowMoment));
		return (
			<div className={"block countdown-block " + style }>
				<div className="block-inner">
					<div className="date-details">
						<div className="date-duration">
							<div>{ duration.years() }Y</div>
							<div>{ duration.months() }M</div>
							<div>{ duration.days() }D</div>
						</div>
						<div className="full-date">{ dateString }</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Countdown