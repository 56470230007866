import React, { Component } from 'react';
// import MenuItem from './MenuItem'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Input from 'core/components/form/Input'
import Select from 'core/components/form/Select'
import ImageUpload from 'core/components/form/ImageUpload'
import MultiRowWrapper from 'core/components/form/MultiRowWrapper'

class MenuItem extends Component {

	constructor(props) {
	    super(props);

	    // this.state = {
	    // 	expanded: true
	    // }

	    this.toggleExpanded = this.toggleExpanded.bind(this);
	}

	toggleExpanded() {
		return false;
		// this.setState({
		// 	expanded: !this.state.expanded
		// })
	}

	render() {
		let item = this.props.item;
		let i = this.props.index;
// if(this.props.theme == 'logos')
// 	console.log(item, item.custom_fields);
		return (
			<div className="block-row">
				<p className="item-title" onClick={this.toggleExpanded}>{item.title}</p>
				{this.props.expanded ?
				<div className="item-main">
					<div className="field-group">
						<Input type="text" name="title" label="Title" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={item.title} />
						<Input type="text" name="class" label="Class" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={item.class} />
						<Input type="text" name="url" label="URL" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={item.url} />
						<Input type="text" name="target" label="Target" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={item.target} />
					</div>
					{ this.props.theme == 'main-menu' ?
						<div className="field-group">
							<Input type="text" name="expanded_title" label="Expanded Title" onInputChange={(name, value) => this.props.onRowChange(i, 'custom_fields', { ...item.custom_fields, [name]: value})} value={ item.custom_fields !== null ? item.custom_fields.expanded_title : ''} />
							<Input type="text" name="expanded_subtitle" label="Expanded Subtitle" onInputChange={(name, value) => this.props.onRowChange(i, 'custom_fields', { ...item.custom_fields, [name]: value})} value={item.custom_fields !== null ? item.custom_fields.expanded_subtitle : ''} />
							<Select name="theme" label="Theme" onInputChange={(name, value) => this.props.onRowChange(i, 'custom_fields', { ...item.custom_fields, [name]: value})} value={item.custom_fields !== null ? item.custom_fields.theme : ''}>
								<option value="default">Default</option>
								<option value="logos">Logos</option>
							</Select>
						</div>
					: ''}
					{ this.props.theme == 'logos' ?
						<div className="field-group">
							<ImageUpload name="logo" onInputChange={(name, value) => this.props.onRowChange(i, 'custom_fields', { ...item.custom_fields, [name]: value})} value={item.custom_fields !== null ? item.custom_fields.logo._id : 0} />
						</div>
					: ''}
				</div> : '' }
					<MenuItemsField name="items" onChange={(name, value) => this.props.onRowChange(i, name, value)} rows={item.items} expanded={this.props.expanded} theme={item.custom_fields !== null ? item.custom_fields.theme : ''}/>
				{this.props.expanded ? 
				<div className="item-main">	
					<button type="button" className="remove-block button" onClick={() => this.props.removeRow(i)}>Remove Item</button>
				</div> : '' }
			</div>
		)
	}
}

class MenuItems extends Component {

	constructor(props) {
	    super(props);

		this.onDragEnd = this.onDragEnd.bind(this);
	}

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }

        this.props.reorder(
          result.source.index,
          result.destination.index
        );
     }

	render() {

		return (
			<div className="blocks">
				<DragDropContext onDragEnd={this.onDragEnd}>
        			<Droppable droppableId="droppable">
        				{(provided, snapshot) => (
        			            <div
        			              {...provided.droppableProps}
        			              ref={provided.innerRef}
        			              className={ (snapshot.isDraggingOver ? 'active-dragging ' : '') }
        			            >
									{this.props.rows.map((item, i) => {
										
										return (
											<React.Fragment>
											{ !this.props.expanded ? 
												<Draggable key={item.title+i} draggableId={item.title+i} index={i}>
												{(provided, snapshot) => (
								                    <div
								                      ref={provided.innerRef}
								                      {...provided.draggableProps}
								                      {...provided.dragHandleProps}
								                      style={provided.draggableProps.style}
								                      className={ (snapshot.isDragging ? 'is-dragging ' : '') }
								                    >
														<MenuItem item={item} onRowChange={this.props.onRowChange} removeRow={this.props.removeRow} index={i} expanded={this.props.expanded} theme={this.props.theme} />
													</div>
                   								)}
											 </Draggable> 
											 : 
											 <MenuItem item={item} onRowChange={this.props.onRowChange} removeRow={this.props.removeRow} index={i} expanded={this.props.expanded} theme={this.props.theme} />
											}
											</React.Fragment>
										);
									})}
								{provided.placeholder}
					        </div>
					    )}
					</Droppable>
				</DragDropContext>
				{this.props.expanded ?
				<div className="add-item">
					<button type="button" className="add button" onClick={() => this.props.addRow()}>
						Add Menu Item <span>+</span>
					</button>
				</div> : '' }
			</div>
		)
	}
}

const MenuItemsField = MultiRowWrapper(MenuItems, {
	title: '',
	class: '',
	url: '',
	target: '',
    items: [],
    'custom_fields': null
});

export default MenuItems;