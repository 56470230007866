import React, {useEffect, useState} from 'react';
import {FileLibraryListItem, ReactMediaLibrary, FileMeta} from 'react-media-library';
import API from 'API'

const ReactMediaLibraryWrapper: React.FC = (props) => {
    const [fileLibraryList, setFileLibraryList] = useState([]);
        
    useEffect(() => {
        // async function loadMedia() {
        //     const media = await getMedia();
        //     setFileLibraryList(media);
        // }
        
        // loadMedia();
        getMedia();
    }, []);

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    async function getMedia() {
        let theAPI = new API();
        const media = await theAPI.getMedia();
        const formattedMedia = media.data.data.map(media => {
            media.createdAt = new Date(media.createdAt);
            return media;
        })
        // return formattedMedia;
        setFileLibraryList(formattedMedia);
    }

    async function uploadCallback(fileBase64: string, fileMeta: FileMeta): Promise<boolean> {
        let file = dataURLtoFile(fileBase64, fileMeta.fileName);

        let theAPI = new API();
        let formData = new FormData();
        formData.append('file', file);
        let results = await theAPI.createMedia(formData)
            .then(() => {
                getMedia();
                
                return true;
            }).catch(() => {
                
                return false;
            });

        return results;
    }

    function selectCallback(item: FileLibraryListItem) {
        // // Hide modal
        props.onHide();

        // // TODO Pass selected file back to client component callback function
        props.onSelect(item);
    }

    async function deleteCallback(item: FileLibraryListItem) {
        let theAPI = new API();
        await theAPI.deleteMedia(item._id)
            .then(() => {
                getMedia();
                
                return true;
            }).catch(() => {
                
                return false;
            });
    }

    return (
        <React.Fragment>
            <ReactMediaLibrary
                show={props.display}
                onHide={props.onHide}
                fileUploadCallback={uploadCallback}
                fileLibraryList={fileLibraryList}
                fileSelectCallback={selectCallback}
                fileDeleteCallback={deleteCallback}
            />
        </React.Fragment>
    );
};

export default ReactMediaLibraryWrapper;