import React, { Component } from 'react';
import ReactMediaLibraryWrapper from './ReactMediaLibraryWrapper'
import API from 'API'

class ImageUpload extends Component {
	constructor(props) {
		super(props);

		this.state = {
			previewURL: this.props.previewURL || "",
			filename: "",
			showMediaLibrary: false
		}

		this.handleChange = this.handleChange.bind(this)
		this.onBrowse = this.onBrowse.bind(this);
		this.hideMediaLibrary = this.hideMediaLibrary.bind(this);
		this.clear = this.clear.bind(this);
	}

	async getMedia(mediaID) {
		if(mediaID === undefined)
			return false;

	    let theAPI = new API();
	    const media = await theAPI.getMediaItem(mediaID);
	    let mediaData = media.data.data

	    this.handleChange(mediaData)
	}

	componentDidMount() {
		if(this.props.value !== 0 && this.props.value !== undefined) {
			this.getMedia(this.props.value);
		}
	}

	componentDidUpdate(prevProps) {
		if((this.props.value !== 0 && prevProps.value === 0) || (this.props.value !== 0 && prevProps.value === undefined)) {
			this.getMedia(this.props.value);
		}
	}

	handleChange(mediaItem) {
		this.props.onInputChange(this.props.name, mediaItem);
		this.setState({
			filename: mediaItem.filename,
			previewURL: mediaItem.thumbnailUrl
		})
	}

	onBrowse() {
		this.setState({ showMediaLibrary: true });
	}

	hideMediaLibrary() {
		this.setState({ showMediaLibrary: false });
	}

	clear() {
		this.props.onInputChange(this.props.name, 0);
		this.setState({
			filename: "",
			previewURL: ""
		})
	}

	render() {
		return (
			<div className="image-upload">
				<div className="preview-wrapper">
					<div className="preview" style={{backgroundImage: "url(" + this.state.previewURL + "?w=160&h=160&fit=crop)"}}></div>
				</div>
				<div className="fake-upload-wrapper">
					<div className="fake-upload">
						<div className="filename">{(this.state.filename) ? this.state.filename : 'Add image' }</div>
						<button className="browse" onClick={this.onBrowse} type="button">Browse</button>
					</div>
					<button className="clear" onClick={this.clear}>Clear</button>
					<ReactMediaLibraryWrapper display={this.state.showMediaLibrary} onHide={this.hideMediaLibrary} onSelect={this.handleChange} />
				</div>
			</div>
		)
	}
}

export default ImageUpload;