export const GET_MENUS_REQUEST = 'GET_MENUS_REQUEST';
export const GET_MENUS_SUCCESS = 'GET_MENUS_SUCCESS';
export const GET_MENUS_FAILURE = 'GET_MENUS_FAILURE';
export const SET_MENUS_ORDER = 'SET_MENUS_ORDER';
export const SET_MENUS_PAGE = 'SET_MENUS_PAGE';
export const SET_MENUS_SEARCH = 'SET_MENUS_SEARCH';

export const GET_MENU_REQUEST = 'GET_MENU_REQUEST';
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS';
export const GET_MENU_FAILURE = 'GET_MENU_FAILURE';
export const SAVE_MENU_REQUEST = 'SAVE_MENU_REQUEST';
export const SAVE_MENU_SUCCESS = 'SAVE_MENU_SUCCESS';
export const SAVE_MENU_VALIDATION = 'SAVE_MENU_VALIDATION';
export const SAVE_MENU_FAILURE = 'SAVE_MENU_FAILURE';
export const DELETE_MENU_REQUEST = 'DELETE_MENU_REQUEST';
export const DELETE_MENU_SUCCESS = 'DELETE_MENU_SUCCESS';
export const DELETE_MENU_FAILURE = 'DELETE_MENU_FAILURE';