import React, { Component } from 'react';
import DatePicker from 'core/components/form/DatePicker'
import Select from 'core/components/form/Select'

export const fieldDefaults = {
	'start': '',
	'end': '',
	'style': 'default'
}

export class Countdown extends Component {
	render() {
		// let imageID = 0;
		// if(this.props.content.image !== undefined)
		// 	imageID = this.props.content.image._id
		return (
			<div className="block">
				<p className="block-title">Countdown</p>
				<div className="block-fields">
					<DatePicker type="text" name="start" label="Start Date" onInputChange={this.props.onChange} value={this.props.content.start} showTimeSelect />
					<DatePicker type="text" name="end" label="End Date" onInputChange={this.props.onChange} value={this.props.content.end} showTimeSelect />
					<Select name="style" label="Style" onInputChange={this.props.onChange} value={this.props.content.style}>
						<option value="default">Default</option>
						<option value="dark">Dark</option>
					</Select>
				</div>
			</div>
		);
	}
}