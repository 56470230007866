import React from 'react';

function MultiRowWrapper(Component, emptyRow) {
    class MultiRow extends Component {
        constructor(props) {
            super(props);

            this.onRowChange = this.onRowChange.bind(this);
            this.removeRow = this.removeRow.bind(this);
            this.addRow = this.addRow.bind(this);
            this.reorder = this.reorder.bind(this);
        }

        onRowChange(index, name, value) {
            let updatedRows = JSON.parse(JSON.stringify(this.props.rows));
            if(updatedRows[index] !== undefined) {
                let currentRow = updatedRows[index];

                currentRow[name] = value;
            } 
            // console.log(updatedRows);
            this.props.onChange(this.props.name, updatedRows)
        }

        removeRow(index) {
            let updatedRows = JSON.parse(JSON.stringify(this.props.rows));
            updatedRows.splice(index, 1);
            this.props.onChange(this.props.name, updatedRows)
        }

        addRow(rowValue) {
            // console.log(rowValue);
            let newRow = emptyRow;
            if(rowValue !== undefined)
                newRow = rowValue;
            let updatedRows = JSON.parse(JSON.stringify(this.props.rows));
            updatedRows.push(JSON.parse(JSON.stringify(newRow)));
            this.props.onChange(this.props.name, updatedRows)
        }

        reorder(startIndex, endIndex) {
            let updatedRows = JSON.parse(JSON.stringify(this.props.rows));
            const [removed] = updatedRows.splice(startIndex, 1);
            updatedRows.splice(endIndex, 0, removed);
            this.props.onChange(this.props.name, updatedRows)
        }

        render() {
            return <Component 
                        onRowChange={this.onRowChange}
                        addRow={this.addRow}
                        removeRow={this.removeRow}
                        reorder={this.reorder}
                        {...this.props} />
        }
    }

    return MultiRow;
}

export default MultiRowWrapper;