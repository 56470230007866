import axios from 'axios'
import { handleErrors } from 'core/modules/Error/actions'
import store from './index';

class API {
	constructor(dispatch, token) {
		this.dispatch = dispatch
		this.token = token
		if(token === undefined)
			this.token = localStorage.getItem('cltoken')
	}

	call(endpoint, method, newOptions, authenticated, headers) {
		let config = {
			method: method,
			url: process.env.REACT_APP_API_URL + '/api/' + endpoint,
			data: newOptions,
			// params: newOptions,
			headers: headers
		}
		if(method === 'get')
			config['params'] = newOptions
		if(authenticated){
			config['headers'] = { ...config['headers'], 'Authorization': 'Bearer ' + localStorage.getItem('cltoken') }
		}

		return axios(config)
		.then(response => response)
		.catch(error => {
			// throw error;
			store.dispatch(handleErrors(error));
		})
	}

	login(email, password) {
		let options = {
			'email': email,
			'password': password
		}
		return this.call('login', 'post', options);
	}

	sendPasswordResetEmail(data) {
		let options = data
		return this.call('password/email', 'post', options);
	}

	resetPassword(data) {
		let options = data
		return this.call('password/reset', 'post', options);
	}

	resendVerification(email) {
		return this.call('email/resend/' + email, 'get');
	}

	getAudits(data) {
		return this.call('audits', 'get', data, true);
	}

	// Users
	getUser(id) {
		return this.call('users/' + id, 'get', {}, true);
	}

	getUsers(data) {
		return this.call('users', 'get', data, true);
	}

	saveUser(id, data) {
		// let formData = new FormData();

	 // 	for(var key in data) {
	 //    	formData.append(key, data[key])
	 //    }
	 //    formData.append('_method', 'put')

		// return this.call('users/' + id, 'post', formData, true);
		return this.call('users/' + id, 'put', data, true);
	}

	createUser(data) {
		return this.call('users', 'post', data, true);
	}

	registerUser(data) {
		return this.call('users/register', 'post', data, false);
	}

	deleteUser(id) {
		return this.call('users/' + id, 'delete', {}, true);
	}

	getActiveRoles(data) {
		return this.call('roles/active', 'get', data, true);
	}

	// Media
	getMedia() {
		return this.call('media', 'get', [], true);
	}

	getMediaItem(id) {
		return this.call('media/' + id, 'get', [], true);
	}

	createMedia(data) {
		return this.call('media', 'post', data, true);
	}

	deleteMedia(id) {
		return this.call('media/' + id, 'delete', {}, true);
	}

	// Page
	getPageBySlug(slug) {
		let data = {
			'slug': slug
		}
		return this.call('pages/slug', 'get', data, false);
	}

	getPage(id) {
		return this.call('pages/' + id, 'get', {}, true);
	}

	getPages(data) {
		return this.call('pages', 'get', data, true);
	}

	savePage(id, data) {
		return this.call('pages/' + id, 'put', data, true);
	}

	createPage(data) {
		return this.call('pages', 'post', data, true);
	}

	deletePage(id) {
		return this.call('pages/' + id, 'delete', {}, true);
	}

	// Menu
	getMenu(id) {
		return this.call('menus/' + id, 'get', {}, true);
	}

	getMenus(data) {
		return this.call('menus', 'get', data, true);
	}

	saveMenu(id, data) {
		return this.call('menus/' + id, 'put', data, true);
	}

	createMenu(data) {
		return this.call('menus', 'post', data, true);
	}

	deleteMenu(id) {
		return this.call('menus/' + id, 'delete', {}, true);
	}
}

export default API;

export function call(endpoint, method, newOptions, authenticated, headers) {
	let theAPI = new API();
	return theAPI.call(endpoint, method, newOptions, authenticated, headers);
}

export function getCurrentUser() {
	return call('users/current', 'get', {}, true);
}