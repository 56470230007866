import React, { Component } from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const MapView = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: props.latitude, lng: props.longitude }}
  >
    {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />}
  </GoogleMap>
))

class Map extends Component {
	render() {
		const { latitude, longitude, address, link_text, link_url } = this.props.content;

		return (
			<div className="block map-block">
				<div className="map-wrapper">
					<MapView
					  isMarkerShown
					  googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAscUDO5gf4MNTeu-fD5Fkh2eZOv8EKj9Y"
					  loadingElement={<div style={{ height: `100%` }} />}
					  containerElement={<div className="map-container" />}
					  mapElement={<div style={{ height: `100%` }} />}
					  latitude={parseFloat(latitude)}
					  longitude={parseFloat(longitude)}
					/>
					<div className="address-wrapper">
						{ address !== '' ? <p className="address">{ address }</p> : '' }
						{ link_url !== '' ? <a href={link_url} className="button">{ link_text }</a> : '' }
					</div>
				</div>
			</div>
		)
	}
}

export default Map