import React, { Component } from 'react'

class TicketDates extends Component {
	render() {
		const { dates } = this.props.content;

		return (
			<div className="block ticket-dates-block">
				<div className="block-inner">
					{ dates.map(date => {
						let { title, link_text, link_url, times } = date;

						return (
							<div className="ticket-date">
								<div className="ticket-date-header">
									{link_text !== "" && link_url !== "" ? <a className="button" href={ link_url }>{ link_text }</a> : ''}
									{title !== "" ? <h3 className="ticket-date-title">{ title }</h3> : ''}
								</div>
								<div className="ticket-times">
									{ times.map( ticketTime => {
										let { title, time, image } = ticketTime;
										return (
											<div className="ticket-time" style={{ backgroundImage: 'url(' + image.thumbnailUrl + '?w=640&h=640&fit=crop)'}}>
												<div className="ticket-time-details">
													{time !== "" ? <p className="ticket-time-time">{ time }</p> : ''}
													{title !== "" ? <p className="ticket-time-title">{ title }</p> : ''}
												</div>
											</div>
										)
									})}
								</div>
							</div>
						)
					})}
				</div>
			</div>
		)
	}
}

export default TicketDates