import React, { Component } from 'react'
import {withRouter} from 'react-router-dom';
import API from 'API'
import Page from '../components/Page'

class PageContainer extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	featuredImage: {},
	    	title: '',
	    	metaDescription: '',
	    	slug: '',
	    	background: '',
	    	blocks: [],
	    	customFields: null
	    }
	}

	async getPage(slug) {
		if(slug === undefined)
			return false;

	    let theAPI = new API();
	    await theAPI.getPageBySlug(slug)
	    	.then(page => {
	    		let pageData = page.data.data

	    		// document.title = 'WJ CMS - ' + pageData.title;

	    		this.setState({
	    			featuredImage: pageData.featuredimage,
	    			title: pageData.title,
	    			metaDescription: pageData.meta_description,
	    			slug: pageData.slug,
	    			background: pageData.background,
	    			blocks: pageData.blocks,
	    			customFields: pageData.custom_fields
	    		});
	    	})
	    	.catch(error => {
		    	if(error.response && error.response.status === 404)
		    		this.props.history.push('/404');
		    		return;
	    	}) 
	}

	updatePage(path) {
		if(path === '/')
			path = '/home';
		if(path !== undefined) {
			path = path.replace(/(^\/)|(\/$)/g, "")
			this.getPage(path);
		}
	}

	componentDidMount() {
		let path = this.props.location.pathname;
		this.updatePage(path)
	}

	componentDidUpdate(prevProps) {
		let path = prevProps.location.pathname;
		let newPath = this.props.location.pathname;
		if(path !== newPath)
			this.updatePage(newPath)
	}

	render() {
		const { featuredImage, title, slug, background, blocks, customFields } = this.state

		return (
			<Page featuredImage={featuredImage} title={title} slug={slug} background={background} blocks={blocks} customFields={customFields} isLoggedIn={this.props.isAuthenticated} user={this.props.user} />
		)
	}
}

export default withRouter(PageContainer);