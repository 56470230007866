import React, { Component } from 'react'
import MenuContainer from 'frontend/modules/Menu/containers/MenuContainer'

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			menuOpen: false,
			scrolled: false
		}
	}

	componentDidMount(){
		window.addEventListener('scroll', () => {
			let scrolled = false;
			if(window.scrollY > 0){
			 	scrolled = true;
			}
			this.setState({ scrolled });
		});
	}

	render() {
		return (
			<header className={"page-header" + (this.state.menuOpen ? ' is-active' : '') + (this.state.scrolled ? ' is-scrolled' : '')}>
				<div className="page-header-inner">
					<div className="header-main">
						<a href="/" className="logo-wrapper">
							<img src={this.props.logo.thumbnailUrl + '?w=200'} alt="Rock the River" className="logo" />
						</a>
						<div className={"main-menu-wrapper" + (this.state.menuOpen ? ' is-active' : '')}>
							<div className="main-menu-wrapper-inner">
								<MenuContainer id={1} className="main-menu" onItemHover={ () => this.setState({ menuOpen: true}) } onItemHoverOut={ () => this.setState({ menuOpen: false}) } />
								<MenuContainer id={2} className="social-menu" />
							</div>
						</div>
						<button className={ "menu-toggle" + (this.state.menuOpen ? ' is-active' : '')} type="button" onClick={ () => this.setState({ menuOpen: !this.state.menuOpen}) }>
							<span>Menu</span>
							<div className="hamburger hamburger--slider">
							  <span className="hamburger-box">
							    <span className="hamburger-inner"></span>
							  </span>
							</div>
						</button>
					</div>
				</div>
			</header>
		)
	}
}

export default Header