import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import Select from 'core/components/form/Select'
import Textarea from 'core/components/form/Textarea'
import ImageUpload from 'core/components/form/ImageUpload'

export const fieldDefaults = {
	'title': '',
	'content': '',
	'link_text': '',
	'link_url': '',
	'image': {},
	'type': 'standard',
	'style': 'default'
}

export class ContentOnImage extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">Content on Image</p>
				<div className="block-fields">
					<Input type="text" name="title" label="Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.title} />
					<Textarea type="text" name="content" label="Content" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.content} />
					<ImageUpload name="image" onInputChange={this.props.onChange} value={this.props.content.image._id} />
					<div className="field-group">
						<Input type="text" name="link_text" label="Link Text" onInputChange={this.props.onChange} value={this.props.content.link_text} />
						<Input type="text" name="link_url" label="Link URL" onInputChange={this.props.onChange} value={this.props.content.link_url} />
					</div>
					<Select name="type" label="Type" onInputChange={this.props.onChange} value={this.props.content.type}>
						<option value="standard">Standard</option>
						<option value="featured">Featured</option>
					</Select>
					<Select name="style" label="Style" onInputChange={this.props.onChange} value={this.props.content.style}>
						<option value="default">Default</option>
						<option value="dark">Dark</option>
					</Select>
				</div>
			</div>
		);
	}
}