import React, { Component } from 'react';
import API from 'API'

class SingleImageUpload extends Component {
	constructor(props) {
		super(props);

		this.state = {
			previewURL: this.props.previewURL || "",
			filename: ""
		}

		this.handleChange = this.handleChange.bind(this)
	}

	async getMedia(mediaID) {
		if(mediaID === undefined)
			return false;

	    let theAPI = new API();
	    const media = await theAPI.getMediaItem(mediaID);
	    let mediaData = media.data.data

	    this.setState({
	    	previewURL: mediaData.thumbnailUrl,
	    	filename: mediaData.filename
	    });
	}

	componentDidMount() {
		if(this.props.value !== 0) {
			this.getMedia(this.props.value);
		}
	}

	componentDidUpdate(prevProps) {
		if(this.props.value !== 0 && prevProps.value === 0) {
			this.getMedia(this.props.value);
		}
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.previewURL !== nextProps.previewURL) {
			this.setState({
				previewURL: nextProps.previewURL
			})
		}
	}

	handleChange(event) {
		event.preventDefault();

	    let reader = new FileReader();
	    let file = event.target.files[0];

	    reader.onloadend = () => {
	      this.setState({
	      	filename: file.name,
	        previewURL: reader.result
	      });
	      console.log(file);
	      this.props.onInputChange(this.props.name, file)
	    }

	    reader.readAsDataURL(file)
	}

	render() {
		return (
			<div className="image-upload">
				<div className="preview-wrapper">
					<div className="preview" style={{backgroundImage: "url(" + this.state.previewURL + ")"}}></div>
				</div>
				<div className="fake-upload-wrapper">
					<div className="fake-upload">
						<div className="filename">{(this.state.filename) ? this.state.filename : 'Add new profile photo' }</div>
						<button className="browse">Browse</button>
					</div>
					<input type="file" onChange={this.handleChange} />
				</div>
			</div>
		)
	}
}

export default SingleImageUpload;