import React from 'react';

function FormWrapper(Component, fields) {
    class Form extends Component {
        constructor(props) {
            super(props);

            let values = {};
            let errors = {};
            fields.map((field, i) => {
                errors[field] = "";
                
                if(typeof field === 'object') {
                    values[field.name] = field.default
                } else {
                    values[field] = ""
                }
                return false;
            })

            if(this.props.editValues !== undefined){
                let existingValues = Form.mapExistingToValues(this.props.editValues);
                values = { ...values, ...existingValues}
            }

            this.state = {
                formUsed: false,
                values: values,
                errors: this.props.apiErrors || errors
            }

            this.onInputChange = this.onInputChange.bind(this)
            this.submit = this.submit.bind(this)
            this.onInputFocusOut = this.onInputFocusOut.bind(this)
        }

        componentDidUpdate(prevProps) {

            if(this.props.editValues !== undefined && prevProps.editValues === undefined) {
                let newExistingValues = Form.mapExistingToValues(this.props.editValues);

                this.setState({
                    values: { ...this.state.values, ...newExistingValues}
                })
            }
            if(this.props.apiErrors !== prevProps.apiErrors) {
                this.setState({
                    errors: { ...this.state.errors, ...this.props.apiErrors}
                });
            }
        }

        static mapExistingToValues(values) {
            let mappedValues = []
            fields.map((field, i) => {
                if(typeof field === 'object') {
                    if(values[field.name] !== undefined){
                        if(field.parseExisting !== undefined)
                            mappedValues[field.name] = field.parseExisting(values[field.name])
                        else
                            mappedValues[field.name] = values[field.name]
                    }
                } else {
                    if(values[field] !== undefined)
                        mappedValues[field] = values[field]
                }
                return false;
            })
            return mappedValues
        }

        isDisabled() {
            // const { errors, formUsed } = this.state
            // const { isLoading } = this.props
            // const hasErrors = Object.keys(errors).some(x => errors[x].length !== 0)
            // return hasErrors || isLoading || !formUsed;
            const { formUsed } = this.state
            const { isLoading } = this.props
            return isLoading || !formUsed;
        }

        onInputFocusOut(name, label) {
            this.validate(name, label);
        }

        onInputChange(name, value) {
            this.validate(name);
            this.setState({
                formUsed: true,
                values: { ...this.state.values, [name]: value}
            })
        }

        submit(event, additionalValues) {
            if(event != null && event !== undefined) {
                event.preventDefault();
                event.stopPropagation();
            }
            // this.state.values.forEach((item, key) => {
            //     console.log(item, key);
            // })
            let errors = [];
            let hasErrors = false;
            for (const [key, value] of Object.entries(this.state.values)) {
                let fieldErrors = this.props.validate(key, undefined, value);
                if(fieldErrors.length > 0){
                    errors[key] = fieldErrors
                    hasErrors = true;
                }
            }
            if(hasErrors) {
                this.setState({
                    errors: { ...this.state.errors, ...errors }
                })
            } else {
                let values = { ...this.state.values, ...additionalValues };
                this.props.onSubmit(values);
            }
        }

        validate(name, label) {
            let value = this.state.values[name];

            let errors = this.props.validate(name, label, value);

            this.setState({
                errors: { ...this.state.errors, [name]: errors }
            })
        }

        render() {
            const { errors, formUsed } = this.state
            const { isLoading, onSubmit, ...otherProps } = this.props
            const hasErrors = Object.keys(errors).some(x => errors[x].length !== 0)
            // const isDisabled = hasErrors || isLoading || !formUsed;
            const isDisabled = this.isDisabled();

            return <Component 
                        onSubmit={this.submit} 
                        onSubmitDraft={this.submitDraft}
                        onSubmitPublish={this.submitPublish}
                        isDisabled={isDisabled}
                        onInputChange={this.onInputChange} 
                        onRemoveFocus={this.onInputFocusOut}
                        values={this.state.values}
                        errors={this.state.errors}
                        isLoading={isLoading}
                        formUsed={formUsed}
                        {...otherProps} />
        }
    }

    return Form;
}

export default FormWrapper;