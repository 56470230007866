import React, { Component } from 'react'

class Content extends Component {
	render() {
		const { title, image, content, type } = this.props.content;

		return (
			<div className={"block content-block " + type }>
				<div className="block-inner">
					{title !== "" ? <h2 className="block-title">{title}</h2> : ''}
					{content !== "" ? <div className="block-content" dangerouslySetInnerHTML={{__html: content}}></div> : ''}
				</div>
			</div>
		)
	}
}

export default Content