import React, { Component } from 'react'

class Tickets extends Component {
	render() {
		const { title, featured_tickets, tickets } = this.props.content;

		return (
			<div className="block tickets-block ">
				<div className="block-inner">
					{title !== "" ? <h2 className="block-title">{title}</h2> : ''}
					<div className="featured-tickets ticket-list">
						{ featured_tickets.map( ticket => {
							let { title, date, price, link_text, link_url } = ticket
							return (
								<div className="ticket">
									{ title !== "" ? <p class="ticket-title">{ title }</p> : ''}
									{ date !== "" ? <p class="ticket-date">{ date }</p> : ''}
									{ price !== "" ? <p class="ticket-price">{ price }</p> : ''}
									{ link_text !== "" && link_url !== "" ? <a class="button" href={ link_url }>{ link_text }</a> : ''}
								</div>
							)
						})}
					</div>
					<div className="standard-tickets ticket-list">
						{ tickets.map( ticket => {
							let { title, date, price, link_text, link_url } = ticket
							return (
								<div className="ticket">
									{ title !== "" ? <p class="ticket-title">{ title }</p> : ''}
									{ date !== "" ? <p class="ticket-date">{ date }</p> : ''}
									{ price !== "" ? <p class="ticket-price">{ price }</p> : ''}
									{ link_text !== "" && link_url !== "" ? <a class="button" href={ link_url }>{ link_text }</a> : ''}
								</div>
							)
						})}
					</div>
				</div>
			</div>
		)
	}
}

export default Tickets