import React, { Component } from 'react'
import MenuContainer from 'frontend/modules/Menu/containers/MenuContainer'

class Footer extends Component {
	render() {
		let currentDate = new Date();

		return (
			<footer className="page-footer">
				<div className="sponsors">
					<div className="sponsor-list">
						{ this.props.sponsors.map((sponsor, i) => {
							return <div className="sponsor-wrapper"><img src={sponsor.logo.thumbnailUrl + '?w=230&h=120&fit=contain'} key={i} /></div>
						})}
					</div>
				</div>
				<div className="footer-bottom">
					<div className="footer-bottom-inner">
						<a href="/" className="logo-wrapper">
							<img src={this.props.logo.thumbnailUrl + '?w=100'} alt="Rock the River" className="logo" />
						</a>
						<div className="footer-bottom-right">
							<MenuContainer id={2} className="social-menu" />
							<p className="copyright">{this.props.copyright.replace('@year@', currentDate.getFullYear())}</p>
							<MenuContainer id={3} className="footer-menu" />
						</div>
					</div>
				</div>
			</footer>
		)
	}
}

export default Footer