import React, {Component} from 'react'
import { connect } from 'react-redux'
import FrontEnd from '../components/FrontEnd'
import Loader from 'admin/components/Loader'
import { logoutUser } from 'admin/modules/Auth/actions'
import { getLoggedInUser} from 'admin/modules/Auth/selectors'
import { userHasPermission } from 'admin/modules/Auth/selectors'
import { getSettings } from 'admin/modules/Settings/actions'
import { getSettingsFetching } from 'admin/modules/Settings/selectors'

class FrontEndContainer extends Component {
    componentWillMount() {
        this.props.getSettings()
    }

    render() {
        return (
            <div className="front-end-wrapper">
                {
                    this.props.isLoading ?
                    <Loader />
                    :
                    <FrontEnd {...this.props} />
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
  return {
    isLoading: getSettingsFetching(state),
    isAuthenticated: state.auth.isAuthenticated,
    user: getLoggedInUser(state),
    canViewDashboard: userHasPermission(state, 'dashboard:view')
  }
}

const mapDispatchToProps = dispatch => {
    return {
        getSettings: () => {
            dispatch(getSettings())
        },
        onLogoutClick: creds => {
            dispatch(logoutUser(creds))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FrontEndContainer)