import React, { Component } from 'react';
import ImageUpload from 'core/components/form/ImageUpload'

class SponsorForm extends Component {
	render() {
		return (
			<div className="blocks">
				{this.props.rows.map((item, i) => {
					let logoID = 0;
					if(item.logo._id !== undefined )
						logoID = item.logo._id
					return (
						<div className="block-row" key={i}>
							<div className="item-main event-date-form">
								<div className="field-group">
									<ImageUpload name="logo" onInputChange={(name, value) => this.props.onRowChange(i, name, value)} value={logoID} />
								</div>
							</div>
							<div className="item-main">	
								<button type="button" className="remove-block button" onClick={() => this.props.removeRow(i)}>Remove Item</button>
							</div>
						</div>
					)
				})}
				<div className="add-item">
					<button type="button" className="add button" onClick={() => this.props.addRow()}>
						Add Sponsor <span>+</span>
					</button>
				</div>
			</div>
		)
	}
}

export default SponsorForm;