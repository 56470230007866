import React, { Component } from 'react';

import { Accordion, fieldDefaults as AccordionDefaults } from './blocks/Accordion'
import { Contact, fieldDefaults as ContactDefaults } from './blocks/Contact'
import { ContentOnImage, fieldDefaults as ContentOnImageDefaults } from './blocks/ContentOnImage'
import { Countdown, fieldDefaults as CountdownDefaults } from './blocks/Countdown'
import { ImageText, fieldDefaults as ImageTextDefaults } from './blocks/ImageText'
import { Map, fieldDefaults as MapDefaults } from './blocks/Map'
import { SquareImageBuckets, fieldDefaults as SquareImageBucketsDefaults } from './blocks/SquareImageBuckets'
import { TicketDates, fieldDefaults as TicketDatesDefaults } from './blocks/TicketDates'
import { Tickets, fieldDefaults as TicketsDefaults } from './blocks/Tickets'
import { Content, fieldDefaults as ContentDefaults } from './blocks/Content'

import Select from 'core/components/form/Select'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

class PageBlocks extends Component {
	components = {
		'accordion': {
			block: Accordion,
			default: AccordionDefaults
		},
		'contact': {
			block: Contact,
			default: ContactDefaults
		},
		'content-on-image': {
			block: ContentOnImage,
			default: ContentOnImageDefaults
		},
		'countdown': {
			block: Countdown,
			default: CountdownDefaults
		},
		'image-text': {
			block: ImageText,
			default: ImageTextDefaults
		},
		'map': {
			block: Map,
			default: MapDefaults
		},
		'square-image-buckets': {
			block: SquareImageBuckets,
			default: SquareImageBucketsDefaults
		},
		'tickets': {
			block: Tickets,
			default: TicketsDefaults
		},
		'ticket-dates': {
			block: TicketDates,
			default: TicketDatesDefaults
		},
		'content': {
			block: Content,
			default: ContentDefaults
		}
	};

	constructor(props) {
	    super(props);

		this.state = {
			'type': ''
		}

		this.addBlock = this.addBlock.bind(this);
		this.changeBlockType = this.changeBlockType.bind(this);
		this.onBlockChange = this.onBlockChange.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	changeBlockType(value) {
		this.setState({
			type: value
		})
	}

	addBlock() {
		let block = this.components[this.state.type]
		// console.log(block.default)
		this.props.addRow({
        	type: this.state.type,
        	content: JSON.stringify(block.default)
    	})
	}

	onBlockChange(index, oldContent, name, value) {
		let newContent = JSON.parse(oldContent);
		newContent[name] = value;

		this.props.onRowChange(index, 'content', JSON.stringify(newContent));
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }

        this.props.reorder(
          result.source.index,
          result.destination.index
        );
     }

	render() {
		return (
			<div className="blocks">
				<DragDropContext onDragEnd={this.onDragEnd}>
        			<Droppable droppableId="droppable">
        				{(provided, snapshot) => (
        			            <div
        			              {...provided.droppableProps}
        			              ref={provided.innerRef}
        			              className={ (snapshot.isDraggingOver ? 'active-dragging ' : '') }
        			            >
									{this.props.rows.map((block, i) => {
										let BlockComponent = this.components[block.type].block;

										return (
											<Draggable key={block.type+i} draggableId={block.type+i} index={i}>
												{(provided, snapshot) => (
								                    <div
								                      ref={provided.innerRef}
								                      {...provided.draggableProps}
								                      {...provided.dragHandleProps}
								                      {...provided.draggableProps.style}
								                      className={ (snapshot.isDragging ? 'is-dragging ' : '') }
								                    >
														<div className="block-row">
															<BlockComponent onChange={(name, value) => this.onBlockChange(i, block.content, name, value)} content={JSON.parse(block.content)} />
															<button type="button" className="remove-block button" onClick={() => this.props.removeRow(i)}>Remove Block</button>
														</div>
													</div>
                  								)}
											</Draggable>
										);
									})}
								{provided.placeholder}
					        </div>
					    )}
					</Droppable>
				</DragDropContext>
				<div className="add-block">
					<Select name="type" label="Block Type" onInputChange={(name, value) => this.changeBlockType(value)}>
						<option value=""></option>
						<option value="accordion">Accordion</option>
						<option value="contact">Contact</option>
						<option value="content">Content</option>
						<option value="content-on-image">Content on Image</option>
						<option value="countdown">Countdown</option>
						<option value="image-text">Image Text</option>
						<option value="map">Map</option>
						<option value="square-image-buckets">Square Image Buckets</option>
						<option value="tickets">Tickets</option>
						<option value="ticket-dates">Ticket Dates</option>
						
					</Select>
					<button type="button" className="add button" onClick={this.addBlock}>
						Add Block <span>+</span>
					</button>
				</div>
			</div>
		)
	}
}

export default PageBlocks;