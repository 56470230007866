import {
	GET_MENU_REQUEST,
	GET_MENU_SUCCESS,
	GET_MENU_FAILURE,
	SAVE_MENU_REQUEST,
	SAVE_MENU_SUCCESS,
	SAVE_MENU_VALIDATION,
	SAVE_MENU_FAILURE,
	DELETE_MENU_REQUEST,
	DELETE_MENU_SUCCESS,
	DELETE_MENU_FAILURE,
	GET_MENUS_REQUEST,
	GET_MENUS_SUCCESS,
	GET_MENUS_FAILURE,
	SET_MENUS_ORDER,
	SET_MENUS_PAGE,
	SET_MENUS_SEARCH,
} from './actionTypes'

import { handleErrors } from 'core/modules/Error/actions'
import API from 'API'

function requestGetMenu() {
	return {
		type: GET_MENU_REQUEST,
		isFetching: true
	}
}

export function receiveGetMenu(menu) {
	return {
		type: GET_MENU_SUCCESS,
		isFetching: false,
		menu
	}
}

export function getMenuError() {
	return {
		type: GET_MENU_FAILURE,
		isFetching: false
	}
}

export function getMenu(id) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetMenu())
		let CLAPI = new API(dispatch);
		return CLAPI.getMenu(id)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetMenu(response.data.data));
                // return response
            })
            .catch(error => {
            	dispatch(getMenuError());
            	dispatch(handleErrors(error));
            });
	}
}

function requestSaveMenu() {
	return {
		type: SAVE_MENU_REQUEST,
		isFetching: true
	}
}

export function receiveSaveMenu(menu) {
	return {
		type: SAVE_MENU_SUCCESS,
		isFetching: false,
		menu
	}
}

export function saveMenuValidation(errors) {
	// console.log(errors);
	return {
		type: SAVE_MENU_VALIDATION,
		isFetching: false,
		errors: errors
	}
}

export function saveMenuError() {
	return {
		type: SAVE_MENU_FAILURE,
		isFetching: false
	}
}

export function saveMenu(id, data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestSaveMenu())
		let CLAPI = new API(dispatch);
		return CLAPI.saveMenu(id, data)
			.then(response => {
				// console.log(response);
                dispatch(receiveSaveMenu(response.data.data));
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(saveMenuValidation(error.response.data.errors));
            	} else {
            		dispatch(saveMenuError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}

export function createMenu(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestSaveMenu())
		let CLAPI = new API(dispatch);
		return CLAPI.createMenu(data)
			.then(response => {
				// console.log(response);
                dispatch(receiveSaveMenu(response.data.data));
                // return response
            })
            .catch(error => {
            	if(error.response && error.response.status === 422) {
            		// console.log(error.response);
            		dispatch(saveMenuValidation(error.response.data.errors));
            	} else {
            		dispatch(saveMenuError());
            		dispatch(handleErrors(error));
            	}
            });
	}
}

function requestDeleteMenu() {
	return {
		type: DELETE_MENU_REQUEST,
		isFetching: true
	}
}

export function receiveDeleteMenu() {
	return {
		type: DELETE_MENU_SUCCESS,
		isFetching: false
	}
}

export function saveDeleteMenuError() {
	return {
		type: DELETE_MENU_FAILURE,
		isFetching: false
	}
}

export function deleteMenu(id, data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestDeleteMenu())
		let CLAPI = new API(dispatch);
		return CLAPI.deleteMenu(id, data)
			.then(response => {
                dispatch(receiveDeleteMenu());
            })
            .catch(error => {
            	dispatch(saveDeleteMenuError());
            	dispatch(handleErrors(error));
            });
	}
}

function requestGetMenus() {
	return {
		type: GET_MENUS_REQUEST,
		isFetching: true
	}
}

export function receiveGetMenus(menus, totalPages) {
	return {
		type: GET_MENUS_SUCCESS,
		isFetching: false,
		menus,
		totalPages
	}
}

export function getMenusError() {
	return {
		type: GET_MENUS_FAILURE,
		isFetching: false
	}
}

export function fetchMenus(data) {
	return dispatch => {
		// Before ajax call
		dispatch(requestGetMenus())
		let CLAPI = new API(dispatch);
		return CLAPI.getMenus(data)
			.then(response => {
				// console.log(response);
				// console.log(response.data);
                dispatch(receiveGetMenus(response.data.data, response.data.meta.last_page));
                // return response
            })
            .catch(error => {
            	dispatch(getMenusError());
            	dispatch(handleErrors(error));
            });
	}
}

export function setMenusOrder(orderBy, orderDir) {
	return {
		type: SET_MENUS_ORDER,
		orderBy,
		orderDir
	}
}

export function setMenusPage(page) {
	return {
		type: SET_MENUS_PAGE,
		page
	}
}

export function setMenusSearch(search) {
	return {
		type: SET_MENUS_SEARCH,
		search
	}
}