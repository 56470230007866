import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import ImageUpload from 'core/components/form/ImageUpload'
import SponsorForm from './SponsorForm'
import MultiRowWrapper from 'core/components/form/MultiRowWrapper'

const SponsorField = MultiRowWrapper(SponsorForm, {
	'logo': {}
});

class SettingsForm extends Component {
	render() {
		const { values, errors } = this.props
		let logoID = 0;
		if(values.logo._id !== undefined )
			logoID = values.logo._id
		let footerLogoID = 0;
		if(values.footer_logo._id !== undefined )
			footerLogoID = values.footer_logo._id
		return (
			<div className="main-content">
				<form className="login-form" onSubmit={this.props.onSubmit}>
					<fieldset>
						<p className="form-title">Global Settings</p>
						<Input type="text" name="site_name" label="Site Name" onInputChange={this.props.onInputChange} value={values.site_name} errors={errors.site_name} />
						<ImageUpload name="logo" onInputChange={this.props.onInputChange} errors={errors.logo} value={logoID} />
						<ImageUpload name="footer_logo" onInputChange={this.props.onInputChange} errors={errors.logo} value={footerLogoID} />
						<Input type="text" name="copyright" label="Copyright" onInputChange={this.props.onInputChange} value={values.copyright} errors={errors.copyright} />
					</fieldset>
					<fieldset>
						<p className="form-title">Sponsors</p>
						<SponsorField name="sponsors" onChange={this.props.onInputChange} rows={values.sponsors} errors={errors.sponsors} />
					</fieldset>
					<fieldset>
						<p className="form-title">404</p>
						<ImageUpload name="error_image" onInputChange={this.props.onInputChange} errors={errors.error_image} value={values.error_image._id} />
						<Input type="text" name="error_title" label="Title" onInputChange={this.props.onInputChange} value={values.error_title} errors={errors.error_title} />
						<Input type="text" name="error_subtitle" label="Subtitle" onInputChange={this.props.onInputChange} value={values.error_subtitle} errors={errors.error_subtitle} />
						<div className="field-group">
					    	<Input type="text" name="error_link_text" label="Link Text" onInputChange={this.props.onInputChange} errors={errors.error_link_text} value={values.error_link_text} />
					    	<Input type="text" name="error_link_url" label="Link URL" onInputChange={this.props.onInputChange} errors={errors.error_link_url} value={values.error_link_url} />
					    </div>
					</fieldset>
				</form>
			</div>
		)
	}
}

export default SettingsForm;