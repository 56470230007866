import React, { Component } from 'react';
import Input from 'core/components/form/Input'
import Select from 'core/components/form/Select'
import WYSIWYG from 'core/components/form/WYSIWYG'
import ImageUpload from 'core/components/form/ImageUpload'

export const fieldDefaults = {
	'title': '',
	'content': '',
	'type': 'standard'
}

export class Content extends Component {
	render() {
		return (
			<div className="block">
				<p className="block-title">Content</p>
				<div className="block-fields">
					<Input type="text" name="title" label="Title" onInputChange={this.props.onChange} removeFocus={this.props.onRemoveFocus} value={this.props.content.title} />
					<WYSIWYG name="content" label="Content" onInputChange={this.props.onChange} value={this.props.content.content} />
					<Select name="type" label="Type" onInputChange={this.props.onChange} value={this.props.content.type}>
						<option value="standard">Standard</option>
						<option value="two-column">Two Column</option>
					</Select>
				</div>
			</div>
		);
	}
}