import React, { Component } from 'react';

class MultiSelect extends Component {
	constructor(props) {
		super(props);

		this.addSelected = this.addSelected.bind(this);
	}

	toggleSelected(itemID) {
		if(this.props.selected.indexOf(itemID) === -1)
			this.addSelected(itemID);
		else
			this.removeSelected(itemID)
	}

	addSelected(itemID) {
		if(this.props.selected.indexOf(itemID) === -1){
			let newSelected = this.props.selected.slice(0);
			newSelected.push(itemID)
			this.props.onChange(this.props.name, newSelected);
		}
	}

	removeSelected(itemID) {
		let itemIndex = this.props.selected.indexOf(itemID)
		if(itemIndex !== -1){
			let newSelected = this.props.selected.slice(0);
			newSelected.splice(itemIndex, 1)
			this.props.onChange(this.props.name, newSelected);
		}
	}

	render() {
		return (
			<div className={ 'multi-select ' + this.props.className }>
				<label>{this.props.label}</label>
				<ul className="item-list">
					{
						this.props.items.map((item, i) => {
							return (
								<li key={item.id} onClick={() => this.toggleSelected(item.id)} className={(this.props.selected.indexOf(item.id) !== -1 ? 'is-selected' : '')}>
									{item.name}
									
								</li>
							)
						})
					}
				</ul>
			</div>
		)
	}
}

export default MultiSelect;