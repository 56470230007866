import React from 'react';
import { PropTypes } from 'prop-types'
import { Route } from 'react-router-dom';

const Authenticated = ({ isAuthenticated, component, loginComponent, ...rest}) => (
	<Route {...rest} render={(props) => {
	    return isAuthenticated ?
	    (React.createElement(component, { ...props, ...rest, isAuthenticated })) :
	    (React.createElement(loginComponent));
	  }} />
);

Authenticated.propTypes = {
  isAuthenticated: PropTypes.bool,
};

export default Authenticated;