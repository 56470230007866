import React, { Component } from 'react'
import { default as Header } from 'frontend/containers/layout/HeaderContainer';
import { default as Footer } from 'frontend/containers/layout/FooterContainer';

import PageContainer from 'frontend/modules/Page/containers/PageContainer';
import NotFoundPage from 'frontend/components/pages/NotFoundPage';
import { Switch, Route} from 'react-router-dom';

import 'frontend/assets/scss/frontend.scss';

class FrontEnd extends Component {
	render() {
		return (
			<div className="front-end page">
				<div className="page-inner">
					<Header />
						<Switch>
							{/*<Route exact path="/" render={() => <Redirect to="/home"/>}/>*/}
						    <Route path="/404" component={NotFoundPage} />
						    <Route component={PageContainer} />
						</Switch>
					<Footer />
				</div>
				
			</div>
		)
	}
}

export default FrontEnd