import React, { Component } from 'react'
import { connect } from 'react-redux'
import Edit from 'admin/components/EditForm'
import Create from 'admin/components/CreateForm'
import PageForm from '../components/PageForm'
import { getPage, savePage, deletePage, createPage } from '../actions'
import { getPagesErrors, getPagesFetching, getCurrentPage, getPageSaveSuccess, getPageDeleteSuccess } from '../selectors'
import FormWrapper from 'core/components/form/FormWrapper'
import API from 'API'

const fields = [
	'title', 
	'slug', 
	'meta_description',
	{
		name: 'blocks',
		default: []
	},
	{
		name: 'custom_fields',
		default: {}
	}]
const EditForm = FormWrapper(Edit, fields)
const CreateForm = FormWrapper(Create, fields)

class PageEditFormContainer extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	menus: [],
	    	preview: false
	    }

		this.validate = this.validate.bind(this);
		this.togglePreview = this.togglePreview.bind(this);
	}

	componentDidMount() {
		const pageID = this.props.match.params.id;
		if(pageID !== undefined)
			this.props.getPage(pageID);
		this.getMenus();
	}

	componentDidUpdate(prevProps) {
		if(prevProps.match.params.id !== this.props.match.params.id){
			if(this.props.match.params.id !== undefined)
				this.props.getPage(this.props.match.params.id);
		}
	}

	async getMenus() {
	    let theAPI = new API();
	    const menus = await theAPI.getMenus();
	    let menuData = menus.data.data

	    this.setState({
	    	menus: menuData
	    });
	}

	validate(name, label, value) {

		let errors = [];
		if(name === 'title' || name === 'slug') {
			if(value.length === 0) {
				errors.push(label + " is required.");
			}
		}

		return errors;
	}

	togglePreview() {
		this.setState({ preview: !this.state.preview })
	}

	render() {
		let buttons = [
			{
				'title': 'Preview',
				'onClick': this.togglePreview
			}
		];

		return (
			<div>
				{
					this.props.match.params.id ?
					<EditForm
						validate={this.validate} 
						name="Page"
						editValues={this.props.page}
						backLink="/admin/dashboard/pages"
						isLoading={this.props.isLoading}
						apiErrors={this.props.apiErrors}
						saveSuccess={this.props.saveSuccess}
						deleteSuccess={this.props.deleteSuccess}
						onSubmit={this.props.onSave}
						onDelete={this.props.onDelete}
						hideStatuses={true}
						buttons={buttons}
					>
						<PageForm menus={this.state.menus} preview={this.state.preview} togglePreview={this.togglePreview}/>
					</EditForm>
					:
					<CreateForm 
						validate={this.validate} 
						name="Page"
						editValues={this.props.page}
						backLink="/admin/dashboard/pages"
						editorLink="/admin/dashboard/page/editor/"
						isLoading={this.props.isLoading}
						apiErrors={this.props.apiErrors}
						saveSuccess={this.props.saveSuccess}
						onSubmit={this.props.onCreate}
						hideStatuses={true}
						buttons={buttons}
					>
						<PageForm menus={this.state.menus} preview={this.state.preview} togglePreview={this.togglePreview}/>
					</CreateForm>
				}
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		isLoading: getPagesFetching(state),
		apiErrors: getPagesErrors(state),
		page: getCurrentPage(state),
		saveSuccess: getPageSaveSuccess(state),
		deleteSuccess: getPageDeleteSuccess(state)
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		onSave: data => {
			dispatch(savePage(props.match.params.id, data))
		},

		onCreate: data => {
			dispatch(createPage(data))
		},

		onDelete: data => {
			dispatch(deletePage(props.match.params.id))
		},

		getPage: id => {
			dispatch(getPage(id))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PageEditFormContainer)