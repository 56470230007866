import {
	GET_SETTINGS,
	GET_SETTINGS_REQUEST,
	GET_SETTINGS_FAILURE
} from './actionTypes'

import { handleErrors } from 'core/modules/Error/actions'
import { getSettings as getSettingsAPI } from './api'

function requestGetPage() {
	return {
		type: GET_SETTINGS_REQUEST,
		isFetching: true
	}
}

function receiveGetSettings(settings) {
	return {
		type: GET_SETTINGS,
		settings
	}
}

export function getPageError() {
	return {
		type: GET_SETTINGS_FAILURE,
		isFetching: false
	}
}

export function getSettings() {
	return dispatch => {
		dispatch(requestGetPage())
		return getSettingsAPI()
			.then(response => {
                dispatch(receiveGetSettings(response.data));
            })
            .catch(error => {
            	dispatch(getPageError());
            	dispatch(handleErrors(error));
            });
	}
}