import React, { Component } from 'react'

class Contact extends Component {
	render() {
		const { title, image, details } = this.props.content;

		return (
			<div className="block contact-block">
				<div className="block-inner">
					<div className="block-right">
						{image !== "" && image !== undefined ? <img className="block-image" src={image.thumbnailUrl + '?w=950&h=550&fit=crop'} /> : ''}
					</div>
					<div className="block-left">
						{title !== "" ? <h2 className="block-title">{title}</h2> : ''}
						<div className="details contact-section">
							{ details.map((detail, i) => {
								let { title, content } = detail;

								return (
									<div className="details-item">
										{ title !== '' ? <p class="contact-section-title">{ title }</p> : ''}
										{ content !== '' ? <p class="contact-section-content">{ content }</p> : ''}
									</div>
								)
							})}
						</div>
					</div>
					
				</div>
			</div>
		)
	}
}

export default Contact